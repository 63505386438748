import React from "react"
import { Link } from "gatsby"
import PageLayout from "../components/new-pagelayout"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = () => {
  return (
    <PageLayout pageTitle={"Om företaget"}>
      <div>
        <section class="relative py-20">
          <div class="container px-4 mx-auto">
            <div class="max-w-3xl mx-auto">
              <span class="text-xs text-blue-400 font-semibold">
                Sprunget ur skaparlust och teknikintresse
              </span>
              <h2 class="mt-8 mb-10 text-4xl font-semibold font-heading">
                Om företaget
              </h2>
              <p class="mb-6 text-xl text-gray-500">
                Drone Bone AB startades 2021 av entreprenören Jonas Estberger.
                Jonas har en lång erfarenhet av produktutveckling i olika roller
                hos ett antal välkända teknikbolag. Tack vare ett stort
                teknikintresse såg han en efterfrågan av larm- och skyddssystem
                mot drönare.
              </p>
              <p class="mb-6 text-xl text-gray-500">
                Idén att starta upp en verksamhet har funnits i några år innan
                företaget startades. Genom ett gediget kontaktnät har
                specialister inom allt från antennlära till statistik kunnat
                bidra med insikter och tips för att snabbt få fram produkter.
              </p>
              <p class="mb-6 text-xl text-gray-500">
                I dag befinner sig Drone Bone AB tidigt i utvecklingsfasen av
                bolagets två första produkter.
              </p>
              <p class="mb-6 text-xl text-gray-500">
                Vill du veta mer? Tveka inte att{" "}
                <Link to="/kontakt">kontakta oss</Link>.
              </p>
            </div>
            <div class="max-w-3xl mx-auto text-center">
              <StaticImage
                class="mt-8 mx-auto h-20 w-20 object-cover object-top rounded-full"
                alt="Jonas Estberger"
                src="../images/grundare.jpeg"
              />
              <p class="mb-2 text-2xl font-semibold font-heading">
                Jonas Estberger
              </p>
              <p class="text-gray-500">VD &amp; Grundare</p>
            </div>
          </div>
        </section>
      </div>
    </PageLayout>
  )
}

export default AboutPage
